/* Definition font family */

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('NotoSansThai Thin'), local('NotoSansThai-Thin'), url(../public/font/NotoSansThai/NotoSansThai-Thin.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('NotoSansThai ExtraLight'), local('NotoSansThai-ExtraLight'), url(../public/font/NotoSansThai/NotoSansThai-ExtraLight.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('NotoSansThai Light'), local('NotoSansThai-Light'), url(../public/font/NotoSansThai/NotoSansThai-Light.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('NotoSansThai Regular'), local('NotoSansThai-Regular'), url(../public/font/NotoSansThai/NotoSansThai-Regular.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('NotoSansThai Medium'), local('NotoSansThai-Medium'), url(../public/font/NotoSansThai/NotoSansThai-Medium.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('NotoSansThai SemiBold'), local('NotoSansThai-SemiBold'), url(../public/font/NotoSansThai/NotoSansThai-SemiBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('NotoSansThai Bold'), local('NotoSansThai-Bold'), url(../public/font/NotoSansThai/NotoSansThai-Bold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'NotoSansThai';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('NotoSansThai ExtraBold'), local('NotoSansThai-ExtraBold'), url(../public/font/NotoSansThai/NotoSansThai-ExtraBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}



@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: local('Poppins Thin'), local('Poppins-Thin'), url(../public/font/Poppins/Poppins-Thin.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'), url(../public/font/Poppins/Poppins-ExtraLight.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local('Poppins Light'), local('Poppins-Light'), url(../public/font/Poppins/Poppins-Light.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local('Poppins Regular'), local('Poppins-Regular'), url(../public/font/Poppins/Poppins-Regular.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local('Poppins Medium'), local('Poppins-Medium'), url(../public/font/Poppins/Poppins-Medium.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'), url(../public/font/Poppins/Poppins-SemiBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local('Poppins Bold'), local('Poppins-Bold'), url(../public/font/Poppins/Poppins-Bold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'), url(../public/font/Poppins/Poppins-ExtraBold.ttf) format('truetype');
    // unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}