.news-page {
    // padding-top: 80px;
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    color: black;

    .news-title {
        padding: 20px 0 30px;
        margin: 0 auto;
        text-align: center;
        font-family: Poppins;
        font-size: 35px;
        font-weight: 600;
        line-height: 60px;
        text-align: center;

    }

    .news-wrapper {
        position: relative;
        padding-top: 30px;
        min-height: 100vh;
        background-color: white;
        z-index: 10;

        .news-list {
            margin: 0 auto;
            max-width: 900px;
            width: 100%;
        }
    }
}

