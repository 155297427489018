.project-view-page {
    position: relative;
    padding-top: 100px;
    width: 100vw;
    min-height: 100vh;
    background-color: #211D26;
    color: white;

    .blog-wrapper {
        position: relative;
        width: 100%;
        background-color: #211D26;
        z-index: 50;
    }

    .blog-contain {
        position: relative;
        margin: 0 auto;
        display: flex;
        max-width: 1250px;
        width: 100%;
        background-color: #211D26;
        min-height: 100vh;
        
        .display-panel {
            max-width: 900px;
            width: 100%;

            .video-wrapper {
                width: 100%;
                background-color: black;
                border-radius: 16px;
                overflow: hidden;

                .videoPlayer {
                    position: relative;
                    width: 100%;
                    z-index: 25;
                }

                .video-list {
                    padding: 0 10px;
                    display: flex;
                    width: 100%;
                    height: 120px;
                    background-color: black;
                    overflow-y: hidden;
                    overflow-x: auto;

                    .videoItem {
                        position: relative;
                        min-width: 193px;
                        width: 193px;
                        height: 108px;
                        background-color: #ccc;
                        border-radius: 8px;
                        margin-right: 10px;
                        background-size: cover;
                        background-position: center;
                        background-repeat: no-repeat;
                        cursor: pointer;

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background-color: rgba(0, 0, 0, .5);
                            z-index: 1;
                            transition-duration: .25s;
                        }

                        &.active::after {
                            background-color: rgba(0, 0, 0, 0);
                            pointer-events: none;
                        }

                        &:hover::after {
                            background-color: rgba(0, 0, 0, .1);
                        }

                        .icon {
                            position: absolute;
                            bottom: 15px;
                            right: 15px;
                            color: white;
                            font-size: 20px;
                            z-index: 2;
                        }
                    }
                }
            }

            .info {
                .title {
                    font-size: 30px;
                    font-weight: 500;
                    font-family: Poppins;
                    margin-bottom: 10px;
                }
            }
        }

        .meta-line {
            display: flex;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            font-family: Poppins;
            color: #D9D9D9;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            svg {
                transform: translateY(3px);
                margin-right: 5px;
            }
        }

        .side-panel {
            margin-left: 22px;
            position: relative;
            max-width: 343px;
            width: 100%;
            min-height: 100vh;
            background-color: #211D26;
            color: white;
    
            .related-box {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
                padding: 16px 24px 16px 24px;
                border-radius: 16px;
                background-color: #FFFFFF0D;

                .related-title {
                    font-size: 18px;
                    font-weight: 500;
                    margin-bottom: 10px;
                    font-family: Poppins;
                }

                .content-list {
                    padding: 15px 0;

                    .content-item {
                        margin-bottom: 8px;
                        display: flex;
                        width: 100%;
                        height: 72px;

                        .thumbnail {
                            width: 128px;
                            height: 72px;
                            min-width: 128px;
                            min-height: 72px;
                            background-color: #ccc;
                            border-radius: 8px;
                            overflow: hidden;
                            margin-right: 10px;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            transition-duration: .25s;
                        }

                        .info {
                            width: calc(100% - 138px);
                            color: white;

                            .title {
                                width: 100%;
                                font-size: 16px;
                                font-weight: 500;
                                margin-bottom: 5px;
                                font-family: Poppins;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }

                            .categoryName {
                                width: 100%;
                                font-size: 12px;
                                font-weight: 400;
                                font-family: Poppins;
                                color: #D9D9D9;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                            }

                            .meta-line {
                                font-size: 12px;
                            }
                        }
                    }
                }

                .btn-view-all {
                    padding: 15px 18px;
                    width: 100%;
                    border-radius: 8px;
                    background-color: white;
                    border: none;
                    font-family: Poppins;
                    cursor: pointer;
                    transition-duration: .25s;

                    &:hover {
                        background-color: #D9D9D9;
                    }

                    &:active {
                        background-color: #ccc;
                        transition-duration: .1s;
                    }
                }
            }

            .team-info {
                .title {
                    font-size: 18px;
                    font-weight: 500;
                    font-family: Poppins;
                    margin-bottom: 10px;
                }

                .team-list {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;

                    .team-item {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        margin-bottom: 10px;

                        .profile-img {
                            width: 92px;
                            height: 92px;
                            border-radius: 8px;
                            background-color: #ccc;
                            background-size: cover;
                            background-position: center;
                            background-repeat: no-repeat;
                            margin-right: 10px;
                        }

                        .profile-info {
                            .name {
                                font-size: 15px;
                                font-weight: 500;
                                font-family: Poppins;
                                margin-bottom: 5px;
                            }

                            .role {
                                font-size: 13px;
                                font-weight: 400;
                                font-family: Poppins;
                                color: #D9D9D9;
                            }

                            .phone {
                                font-size: 13px;
                                font-weight: 400;
                                font-family: Poppins;
                                color: #fff;
                            }

                            .contact-list {
                                display: flex;

                                .contact-item {
                                    width: 21px;
                                    height: 21px;
                                    border-radius: 3px;
                                    background-color: white;
                                    color: black;
                                    text-align: center;
                                    margin-right: 10px;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: #D9D9D9;
                                    }
                                }
                            }
                        }
                    }


                }
            }
        }
    }

    .tab-container {
        width: 100%;

        .tab-list {
            display: flex;
            padding: 0 15px;
            justify-content: start;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid white;

            .tab-item {
                position: relative;
                padding: 20px 15px;
                color: #FFFFFF80;
                font-size: 16px;
                cursor: pointer;

                &:hover {
                    color: white;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 6px;
                    background-color: transparent;
                    border-top-left-radius: 8px;
                    border-top-right-radius: 8px;
                    transition-duration: .25s;
                }

                &.active {
                    color: white;

                    &::after {
                        background-color: #C49B07;
                    }
                }
            }
        }

        .tab-content {
            display: none;
            padding: 25px 15px;
            font-family: "NotoSansThai";
            font-size: 16px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            white-space: pre-line;

            &.active {
                display: block;
            }
        }
    }
}

@media screen and (max-width: 900px) {

    .project-view-page {
        .blog-contain {
            flex-direction: column;

            .info {
                padding: 20px 20px 0;
                width: 100%;
            }

            .side-panel {
                margin: 0 auto;
                max-width: calc(100% - 40px);

                .related-box {
                    height: unset !important;
                    padding-bottom: 30px;

                    .group-top {
                        .content-list {
                            .content-item {
                                margin-bottom: 20px;
                                width: 100%;

                                .info {
                                    padding-top: 0;
                                    padding-left: 10px;
                                    padding-right: 0;
                                }
                            }
                        }
                    }
                }

                .team-info {
                    padding: 0 10px;
                    width: 100%;
                    .title {
                        text-align: left;
                        font-size: 20px;
                    }
                    .team-list {
                        width: 100%;
                        .team-item {
                            margin-bottom: 15px;
                            width: 100%;

                            .profile-img {
                                width: 90px;
                                height: 90px;
                            }
                        }
                    }
                }
            }
        }

        .tab-container {
            padding: 0 20px;

            .tab-content {
                padding: 25px 0;
            }
        }
    }
}