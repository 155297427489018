.contentItem {
    position: relative;
    padding: 20px;
    display: flex;
    width: 100%;
    cursor: pointer;
    cursor: pointer;
    transition-duration: .25s;
    border-radius: 10px;

    &:hover {
        background-color: rgba(0, 0, 0, .05);
    }

    &:active {
        transform: scale(.995);
        background-color: rgba(0, 0, 0, .1);
        transition-duration: .1s;
    }

    &::after {
        content: '';
        position: absolute;
        bottom: 1px;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: rgba(0, 0, 0, .2);
        z-index: 1;
    }
    
    .img {
        width: 350px;
        height: 200px;
        min-width: 350px;
        min-height: 200px;
        background-color: #313131;
        background-position: center;
        background-size: cover;
        border-radius: 16px;
    }

    .info {
        margin-left: 35px;
        width: 100%;
        color: black;
        font-size: 12px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
            //styleName: English/h5;
            font-family: Poppins;
            font-size: 25px;
            font-weight: 500;
            line-height: 40px;
            text-align: left;

        }

        .bottomInfo {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 15px;

            .readMore {
                padding: 8px 15px;
                font-size: 18px;
                font-family: Poppins;
                font-weight: 500;
                white-space: nowrap;
                background-color: white;
                border-radius: 10px;
                border: 1px solid #1A142380;
                opacity: 0px;
                cursor: pointer;
                outline: 2px solid transparent;
                transition-duration: .15s;

                &:hover {
                    background-color: #1A1423;
                    color: white;
                }

                &:active {
                    background-color: #1A1423;
                    color: white;
                    outline: 4px solid #1A142380;
                    transition-duration: .1s;
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .contentItem {
        padding: 20px 10px;
        flex-direction: column;

        .img {
            width: 100%;
            height: 155px;
            min-width: 200px;
            min-height: 100px;
        }

        .info {
            margin-left: 0;
            margin-top: 10px;
            .name {
                font-size: 20px;
                line-height: 30px;
            }
        }

        .bottomInfo {
            margin-top: 20px;
            .readMore {
                display: none;
            }
        }
    }
}