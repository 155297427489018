.contact-page {
    position: relative;
    // padding-top: 80px;
    width: 100vw;
    height: 100vh;
    background-color: white;
    color: black;

    .contact-wrapper {
        margin: 0 auto;
        display: flex;
        max-width: 1000px;
        width: 100%;
        height: calc(100% - 35px);

        .contact-side {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .contact-title {
                font-family: Poppins;
                font-size: 70px;
                font-weight: 600;
                line-height: 60px;
            }

            .content-container {
                width: 100%;
                text-align: left;
            }

            .address-line {
                align-items: center;
                margin: 20px 0;
                font-size: 25px;
            }

            .contact-line{
                font-size: 25px;
                
                .contact-item {
                    display: flex;

                    .contact-topic {
                        width: 100px;
                        font-weight: 600;
                        color: #1A142380;
                    }

                    .contact-value {
                        font-weight: 400;
                    }
                }
            }

            .social-line {
                display: flex;
                margin: 20px 0;
                font-size: 55px;
                
                a {
                    color: #1A142380;
                    transition-duration: .25s;

                    &:hover {
                        color: #C49B07;
                    }
                }

                .social-item + .social-item {
                    margin-left: 30px;
                }
            }

            .big-txt-container {
                text-align: left;

                .big-txt {
                    margin: 0;
                    line-height: 170px;
                    font-family: 'Poppins', sans-serif;
                    font-size: 200px;
                    font-weight: 600;
                    color: #C49B07;
                }
            }
        }
    }

    .bottomLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        width: 100%;
        height: 35px;
        background-color: #C49B07;

        a {
            color: black;
        }
    }
}



@media screen and (max-width: 900px) {

    .contact-page {
        .contact-wrapper {
            flex-direction: column;
            .contact-side {
                padding: 0 20px;
                width: 100%;

                &.big-txt {
                    display: none;
                }

                .contact-title {
                    font-size: 40px;
                    line-height: 30px;
                }

                .address-line {
                    font-size: 20px;
                }

                .contact-line {
                    font-size: 20px;
                }

                .social-line {
                    font-size: 30px;
                }
            }
        }

        .bottomLine {
            padding: 10px 20px;
            flex-direction: column;
            height: unset;
            text-align: center;
        }
    }
}