.galleryGrid3x3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    width: 100vw;
    height: 100vh;
}

@media screen and (max-width: 900px) {
    .galleryGrid3x3 {
        grid-template-columns: 1fr;
        grid-template-rows: unset;
        height: unset;
    }
}