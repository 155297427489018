.project-page {
    position: relative;
    padding-bottom: 150px;
    width: 100%;
    height: 100vh;
    background-color: black;
    color: white;
    z-index: 100;
    overflow-x: hidden;
    overflow-y: auto;

    .floating-filter {
        position: fixed;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 200;

        .float-container {
            position: relative;
            display: flex;
            padding: 7px;
            max-width: 100vw;
            width: 850px;
            height: 70px;
            background-color: #FFFFFFCC;
            backdrop-filter: blur(30px);
            border-radius: 15px;
            box-shadow: 0 5px 10px rgba(255, 255, 255, 0);
            z-index: 100;

            .exhibition-logo {
                min-width: 66px;
                width: 66px;
                height: 100%;
                background-color: white;
                border-radius: 8px;
                transition-duration: .25s;
                cursor: pointer;

                &:hover {
                    background-color: rgb(236, 236, 236);
                }

                &:active {
                    background-color: rgb(209, 209, 209);
                    transition-duration: .1s;
                }

                .img {
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-position: center;
                    background-repeat: no-repeat;
                }
            }

            .filter-dropdown {
                position: relative;
                // min-width: 235px;
                width: 100%;
                margin-left: 8px;
                
                .dropdown-crr-select {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 100%;
                    padding: 5px 20px;
                    cursor: pointer;
                    height: 100%;
                    color: black;
                    background-color: white;
                    border-radius: 8px;
                    transition-duration: .25s;

                    &:hover {
                        background-color: rgb(236, 236, 236);
                    }

                    &.active {
                        background-color: white;
                        border-top-left-radius: 0px;
                        border-top-right-radius: 0px;
                        box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
                    }
                }

                .name {
                    margin-left: 10px;
                    font-size: 16px;
                    white-space: nowrap;
                    pointer-events: none;
                }
    
                .arrow {
                    margin-left: 20px;
                    pointer-events: none;
                }

                .dropdown-items {
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                    width: 100%;
                    background-color: white;
                    border-radius: 8px;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    box-shadow: 0 -10px 10px rgba(0, 0, 0, .1);
                    opacity: 0;
                    transition-duration: .25s;
                    pointer-events: none;
                    transform: translateY(10px);
                    z-index: 10;
                    overflow: hidden;
        
                    &.active {
                        opacity: 1;
                        pointer-events: auto;
                        transform: translateY(0);
                    }
        
                    ul {
                        list-style: none;
                        padding: 0;
                        margin: 0;
                        width: 100%;
                        flex-direction: column;
        
                        li {
                            margin: 0;
                            padding: 8px 10px;
                            padding-left: 20px;
                            display: flex;
                            width: 100%;
                            color: black;
                            align-items: center;
                            justify-content: start;
                            white-space: nowrap;
                            transition-duration: .25s;
                            cursor: pointer;
        
                            &:hover {
                                background-color: rgba(0, 0, 0, .1);
                            }

                            &.active {
                                background-color: rgba(0, 0, 0, .2);
                            }
        
                            img {
                                margin-right: 10px;
                                width: 30px;
                                height: 30px;
                                border-radius: 100%;
                                background-color: #ccc;
                            }
        
                            a {
                                font-size: 18px;
                                color: inherit;
                            }
                        }
                    }
                }
            }

            .iconPanel {
                margin: 0 25px;
                display: flex;
                align-items: center;

                .icon {
                    cursor: pointer;
                    color: #1A142380;
                    font-size: 30px;
                    transition-duration: .25s;

                    &:hover {
                        color: #1a1423bb;
                        transform: scale(1.05);
                    }

                    &:active {
                        color: #1a1423;
                        transform: scale(1);
                    }
    
                    &.active {
                        color: black;
                        transform: scale(1.05);
                    }
                }

                .icon + .icon {
                    margin-left: 10px;
                }
            }

            .button-item {
                margin-left: 8px;
                height: 100%;
                padding: 5px 20px;
                cursor: pointer;
                height: 100%;
                color: black;
                font-weight: 600;
                font-family: Poppins;
                background-color: white;
                border-radius: 8px;
                border: none;
                transition-duration: .25s;

                &:hover {
                    background-color: rgb(236, 236, 236);
                }

                &:active {
                    background-color: rgb(209, 209, 209);
                }
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .project-page {

        .floating-filter {
            .float-container {
                .filter-dropdown {
                    margin-left: 4px;
                    margin-right: 4px;

                    .dropdown-crr-select  {
                        padding: 0 10px;
                    }
                }
            }
        }
    }
}