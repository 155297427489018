.header {
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px 0;
    width: 100%;
    // height: 80px;
    z-index: 100;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;

    .backdrop-floating {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, .5);
        backdrop-filter: blur(5px);
        z-index: -1;
        pointer-events: none;
    }

    .header-container {
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        color: black;

        ul {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                margin: 0 35px;
                padding: 0 10px;
                font-size: 18px;

                a {
                    position: relative;
                    color: inherit;

                    &::after {
                        content: '';
                        display: block;
                        width: 0;
                        height: 2px;
                        background-color: black;
                        transition-duration: .25s;
                    }

                    &:hover::after,
                    &.active::after {
                        width: 100%;
                    }
                }
            }

            &.white {
                color: white;
    
                li {
                    a {
                        &::after {
                            background-color: white;
                        }
                    }
                }
            }
        }
    }

    .exhibitionItem {
        margin: 0 15px;
        position: relative;
        height: 100%;

        .bannerContainer {
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 5px 10px;
            cursor: pointer;
            width: 100%;
            height: 100%;
            color: black;
            background-color: white;
            border-radius: 16px;
            transition-duration: .25s;

            &:hover {
                background-color: rgb(245, 245, 245);
            }

            &.active {
                background-color: white;
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
                box-shadow: 0 -5px 10px rgba(0, 0, 0, .1);
                z-index: 200;
            }

            .img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                background-color: #ccc;
                pointer-events: none;
            }

            .name {
                margin-left: 10px;
                font-size: 18px;
                pointer-events: none;
            }

            .arrow {
                margin-left: 10px;
                pointer-events: none;
            }
        }

        .exhibitionDropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            color: black;
            background-color: white;
            border-radius: 16px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, .1);
            opacity: 0;
            transition-duration: .25s;
            pointer-events: none;
            transform: translateY(-10px);
            z-index: 10;
            overflow: hidden;

            &.active {
                opacity: 1;
                pointer-events: auto;
                transform: translateY(0);
                z-index: 200;
            }

            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                width: 100%;
                flex-direction: column;

                li {
                    margin: 0;
                    padding: 5px 10px;
                    padding-left: 20px;
                    display: flex;
                    width: 100%;
                    align-items: center;
                    justify-content: start;
                    white-space: nowrap;
                    transition-duration: .25s;
                    cursor: pointer;

                    &:hover {
                        background-color: rgba(0, 0, 0, .1);
                    }

                    img {
                        margin-right: 10px;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        background-color: #ccc;
                    }

                    a {
                        font-size: 18px;
                        color: inherit;
                    }
                }
            }
        }
    }

    .mobile-logo {
        display: none;
    }
    
    .mobile-hamburger {
        display: none;
    }
}

@media screen and (max-width: 900px) {

    .header {
        padding: 0;
        z-index: 1000;
        height: 100%;
        max-height: 80px;
        transition-duration: .25s;
        overflow: hidden;

        .backdrop-floating {
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 80px;
            background-color: rgba(0, 0, 0, .5);
            z-index: 100;
        }

        &.active {
            z-index: 2000;
            max-height: 100vh;
        }

        .mobile-logo {
            display: block;
            position: absolute;
            top: 20px;
            left: 20px;
            width: 40px;
            height: 40px;
            background-color: white;
            border-radius: 100%;
            cursor: pointer;
            z-index: 110;
        }

        .mobile-hamburger {
            display: flex;
            position: absolute;
            top: 20px;
            right: 20px;
            width: 40px;
            height: 40px;
            justify-content: center;
            align-items: center;
            font-size: 20px;
            background-color: white;
            border-radius: 4px;
            cursor: pointer;
            z-index: 110;

            &.active {
                background-color: #C49B07;
                color: white;
            }
        }

        .header-container {
            padding: 80px 0 0;
            height: 100vh;
            opacity: 0;
            background-color: #1A1423;
            color: white;
            transition-duration: .25s;

            &.active {
                position: relative;
                opacity: 1;
                z-index: 100;
            }

            ul {
                padding: 0 20px;
                align-items: start;
                flex-direction: column;
                justify-content: start;
                
                li {
                    padding: 20px 0;
                    margin: 0;
                    width: 100%;
                    font-family: Poppins;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 48px;
                    text-align: left;
                    border-bottom: 1px solid rgba(255, 255, 255, .5);

                    a {
                        &::after {
                            display: none;
                        }
                    }
                }
            }

            .exhibitionItem {
                margin: 0;
                padding: 20px 0;
                width: 100%;
                height: unset;
                box-shadow: none;
                border-bottom: 1px solid rgba(255, 255, 255, .5);

                .bannerContainer {
                    padding: 0;
                    height: unset;
                    background-color: transparent;
                    color: white;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 48px;
                    text-align: left;
                    justify-content: space-between;
                    box-shadow: none;

                    .img {
                        display: none;
                    }

                    .name {
                        margin-left: 0;
                        width: 100%;
                        font-size: 32px;
                        font-weight: 400;
                        line-height: 48px;
                        text-align: left;    
                    }
                }

                .exhibitionDropdown {
                    display: none;
                    position: relative;
                    top: 0;
                    background-color: transparent;
                    color: white;
                    box-shadow: none;

                    &.active {
                        display: block;
                        opacity: 1;
                        pointer-events: auto;
                        transform: translateY(0);
                        z-index: 200;
                    }

                    ul {
                        li {
                            padding-left: 10px;
                            font-size: 25px;
                            font-weight: 400;
                            line-height: 48px;
                            text-align: left;
                            border-bottom: none;

                            img {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}