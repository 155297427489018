.news-read-page {
    // padding-top: 80px;
    width: 100vw;
    min-height: 100vh;
    background-color: white;
    color: black;

    .news-read-container {
        position: relative;
        width: 100%;
        min-height: 100vh;
        background-color: white;
        color: black;
        z-index: 10;

        .article-content {
            margin: 0 auto;
            padding: 50px 0 20px;
            max-width: 900px;
            width: 100%;

            .article-thumbnail {
                width: 100%;
                height: 500px;
                background-color: #ccc;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                border-radius: 20px;
            }

            .article-title {
                padding: 20px 0 10px;
                margin: 0 auto;
                text-align: left;
                font-family: Poppins;
                font-size: 35px;
                font-weight: 600;
                line-height: 60px;
            }

            .article-content-txt {

                .sub-text {
                    margin: 0 auto 20px;
                    text-align: left;
                    font-family: Poppins;
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 40px;
                    text-align: left;
                }

                .content-display {
                    white-space: pre-wrap;
                }
            }
        }

        .news-list {
            margin: 0 auto;
            max-width: 900px;
            width: 100%;
            padding: 50px 0;
        }
    }
}



@media screen and (max-width: 900px) {
    .news-read-page {
        padding-top: 0;

        .news-read-container {
            .article-content {
                padding: 30px 20px;

                .article-thumbnail {
                    height: 220px;
                }
                .article-title {
                    font-size: 23px;
                    line-height: 30px;
                }

                .article-content-txt {
                    margin-top: 20px;
                    .sub-text {
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
            }

            .news-list {
                padding: 0 20px;
            }
        }
    }
}