.footer {
    position: relative;
    padding-top: 20px;
    width: 100%;
    min-height: 100px;
    background-color: white;
    z-index: 10;

    .siteBigTitle {
        margin: 0;
        font-size: 10vw;
        text-align: center;
        font-family: Poppins;
    }

    .menuBlackPane {
        position: relative;
        padding: 50px 60px;
        z-index: 10;
        width: 100%;
        min-height: 400px;
        background-color: #1A1423;

        .col-3 {
            display: grid;
            grid-template-columns: 2fr 1fr 1fr;
            margin: 0 auto;
            max-width: 1500px;
            width: 100%;
            height: 100%;
            padding: 20px 0;

            .item {
                .topic {
                    margin-bottom: 30px;
                    font-family: Poppins;
                    font-size: 23.04px;
                    font-weight: 500;
                    line-height: 34.56px;
                    text-align: left;
                    color: #C49B07;
                }

                .address-box {
                    margin: 30px 0;

                    .address {
                        margin: 0 0;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: white;
                    }
                }

                ul {
                    list-style-type: none;
                    padding: 0;
                    margin: 0;

                    li {
                        padding: 10px 0;
                        font-family: Poppins;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        text-align: left;
                        color: white;
                    }
                }
            }
        }

        a {
            text-decoration: none;
            color: white;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .imageFooterBanner {
        position: relative;
        width: 100%;
        height: 400px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;

        .buLogo {
            position: absolute;
            left: 100px;
            bottom: 30px;
        }

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, .15);
            z-index: 1;
        }
    }

    .bottomLine {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 100px;
        width: 100%;
        height: 35px;
        background-color: #C49B07;

        a {
            color: black;
        }
    }
}

@media screen and (max-width: 900px) {
    .footer {
        .menuBlackPane {
            .col-3 {
                grid-template-columns: 1fr;
            }
        }

        .imageFooterBanner {
            .buLogo {
                left: 50%;
                transform: translateX(-50%);
                max-width: 90%;
            }
        }

        .bottomLine {
            padding: 10px 20px;
            flex-direction: column;
            height: unset;
            text-align: center;
        }
    }
}