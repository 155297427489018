.linkWrapper {
    display: block;
    width: 100%;
    height: 100%;
}

.contentItem {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 230px;
    cursor: pointer;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .1);
        z-index: 60;
        transition-duration: .25s;
        opacity: 0;
    }

    &:hover {
        &::after {
            opacity: 1;
        }
    }

    &:active {
        &::after {
            opacity: 1;
            background-color: rgba(0, 0, 0, .3);
            transition-duration: .1s;
        }
    }

    .img {
        position: relative;
        width: 100%;
        height: 100%;
        background-color: #313131;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 50;
        // transition-duration: .25s;

        
    }

    .info {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 15px);
        padding: 20px 15px;
        color: white;
        font-size: 14px;
        display: flex;
        justify-content: start;
        z-index: 55;

        .item {
            background-color: rgba(0, 0, 0, .75);
            padding: 5px 7px;
            border-radius: 5px;
            margin: 0 5px;
        }
    }
}