.landing-page {
    // padding-top: 80px;
    width: 100%;
    min-height: 100vh;
    background-color: #eeeeee; //#245476;

    color: black; //#ffa700;

    .btn-watch-now {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .wrapperGalleryLanding {
        position: relative;
        z-index: 100;
        height: 130vh;

        .gallery-view {
            height: 100%;
            backdrop-filter: blur(30px);
        }

        .see-more {
            position: absolute;
            bottom: 40px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 200;
            
            .btn-floating {
                padding: 7px 25px;
                color: black;
                font-family: inherit;
                font-size: 15px;
                font-weight: 500;
                background-color: white;
                border: none;
                outline: 5px solid #FFFFFFCC;
                border-radius: 8px;
                cursor: pointer;
                transition-duration: .25s;
                box-shadow: 0 5px 10px rgba(255, 255, 255, 0);

                &:hover {
                    outline: 5px solid #FFF;
                    box-shadow: 0 0 20px 5px rgba(255, 255, 255, .25);
                }

                &:active {
                    outline: 5px solid #FFF;
                    box-shadow: 0 0 20px 5px rgba(255, 255, 255, .25);
                    transition-duration: .1s;
                }
            }
        }
    }
    .gallery-grid-2x2 {
        width: 100%;
        height: 100vh;
        background-color: black;
    }

    .news-wrapper {
        position: relative;
        padding-top: 100px;
        // min-height: 100vh;
        background-color: white;
        z-index: 100;

        .news-list {
            margin: 0 auto;
            max-width: 900px;
            width: 100%;
        }
    }
}

@media screen and (max-width: 900px) {
    .landing-page {
        .wrapperGalleryLanding,
        .news-wrapper,
        .gallery-grid-2x2 {
            height: unset !important;
            z-index: 1100 !important;
        }
    }
}