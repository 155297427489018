.hero-landing {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // background-attachment: fixed;
}

@media screen and (max-width: 900px) {
    .hero-landing {
        max-height: 60vh;
    }
}