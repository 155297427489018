@import 'FontFace.scss';

:root {
    --primary-color: #ffffff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    margin: 0;
    font-family: 'NotoSansThai', sans-serif;
    background-color: #1A1423;
    scroll-behavior: smooth;
}

body {
    overflow-x: hidden;
    overflow-y: auto;
}

h1, h2, h3, h4, h5, h6, p {
    margin: 0
}

a{
    color: white;
    text-decoration: none;
    outline: 0;
}

img{
    pointer-events: none;
}

.loadingCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.centerContain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.preloadOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
}

.btn-black {
    margin: 0 auto;
    display: flex;
    padding: 15px 30px;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Poppins;
    font-size: 15px;
    font-weight: 500;
    background-color: black;
    border: unset;
    border-radius: 12px;
    cursor: pointer;
    transition-duration: .25s;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);

    &:hover {
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .5);
    }

    &:active {
        transform: scale(.95);
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
        transition-duration: .1s;
    }

    svg {
        font-size: 20px;
    }

    svg + span {
        margin-left: 10px;
    }
}

.topic-title {
    padding: 20px 0 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    max-width: 1200px;
    width: 100%;
    // height: 80px;
    // background-color: #eeeeee; //#245476;
    font-size: 23px;
    color: #1a1423be;
    z-index: 20;

    p {
        white-space: nowrap;
    }

    .hr {
        margin: 0 50px;
        width: 100%;
        height: 1px;
        background-color: black;
    }

    .icon-center {
        margin: 0 20px;

        svg {
            font-size: 35px;
        }
    }
}

.scrollToTopBtn {
    position: fixed;
    bottom: 50px;
    right: 50px;
    z-index: 100;

    .scrollToTopBtnInner {
        position: relative;
        width: 50px;
        height: 50px;
        background-color: white;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
        transition-duration: .25s;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, .5);
        }

        &:active {
            transform: scale(.95);
            box-shadow: 0 0 10px 5px rgba(0, 0, 0, .2);
            transition-duration: .1s;
        }

        svg {
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 900px) {
    .topic-title {
        padding: 20px 10px 30px;
        font-size: 20px;

        .hr {
            margin: 0 20px;
        }
    }

    .hide-on-mobile {
        display: none !important;
    }

    .mobile-free-height {
        height: unset !important;
    }
}